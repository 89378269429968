import { ComponentType, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";

import { IViewProps } from "models/layout";
import { useAppSelector } from "store/app";
import { useBasketSelector, basketThunks } from "store/basket";
import { useMenuSelector, menuThunks } from "store/menu";
import { useCustomDispatch } from "store/useStore";

// HOC for custom data load in all page views
export default function withInitializedData(WrappedComponent: ComponentType<IViewProps>) {
  return function (props: IViewProps) {
    const { dataOptions } = props;

    let [dataLoadIsInitialized, setDataLoadIsInitialized] = useState(false);

    let [startBasketLoad, setStartBasketLoad] = useState(false);
    let [startCategoriesLoad, setStartCategoriesLoad] = useState(false);

    const { appIsInitialized, module } = useAppSelector();
    const { categories, menuId } = useMenuSelector();
    const { basket } = useBasketSelector();
    const dispatch = useCustomDispatch();
    const { pathname } = useLocation();

    // Data load triggers
    useEffect(() => {
      if (startBasketLoad && !basket.id) {
        setStartBasketLoad(false);
        dispatch(basketThunks.loadBasket());
      }
    }, [basket.id, startBasketLoad, dispatch]);

    useEffect(() => {
      if (startCategoriesLoad && module.productionUnitId && module.menuInstanceId && categories.length === 0) {
        setStartCategoriesLoad(false);
        dispatch(menuThunks.loadMenu({ menuInstanceId: module.menuInstanceId, existingMenuId: menuId, productionUnitId: module.productionUnitId }));
      }
    }, [module.productionUnitId, module.menuInstanceId, menuId, categories, startCategoriesLoad, dispatch]);

    let loadOptionsData = useCallback(() => {
      setDataLoadIsInitialized(true);

      if (dataOptions) {
        const { useBaskets, useCategories } = dataOptions;

        if (useBaskets) {
          setStartBasketLoad(true);
        }
        if (useCategories) {
          setStartCategoriesLoad(true);
        }
      }
    }, [dataOptions]);

    // Loading predefined data options for the view, like basket and categories
    useEffect(() => {
      if (appIsInitialized && !dataLoadIsInitialized) {
        loadOptionsData();
      }
    }, [appIsInitialized, dataLoadIsInitialized, loadOptionsData]);

    // Refreshing data after navigation
    useEffect(() => {
      setDataLoadIsInitialized(false);
    }, [pathname]);

    return <WrappedComponent {...props} />;
  };
}
