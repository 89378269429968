import { useEffect } from "react";
import { useNavigate } from "react-router";

import Header from "components/navigation/Header/Header";
import PauseScreen from "components/ui/screens/PauseScreen";
import Spinner from "components/ui/Spinner";

import { IViewProps } from "models/layout";
import useAppState from "hooks/useAppState";
import useAppRedirects from "hooks/useAppRedirects";
import useUserActivity from "hooks/useUserActivity";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, layoutOptions, children } = props;
  const { isStatusPage = false, hideBasket = false, hideSidebar = false, hideHeader = false, hidePauseScreen = false } = layoutOptions || {};

  const { isError, isLoading, loadingMessageKey } = useAppState();
  const { userIsInactive } = useUserActivity();

  const redirect = useNavigate();
  const redirectPath = useAppRedirects({ isError, isLoading });

  useEffect(() => {
    if (redirectPath) {
      redirect(redirectPath);
    }
  }, [redirectPath, redirect]);

  return (
    <div className={`page-view ${isStatusPage ? "status-page" : ""} ${pageKey}-page`}>
      {!hideHeader && <Header hideBasket={hideBasket} hideSidebar={hideSidebar} />}

      {!hidePauseScreen && userIsInactive && <PauseScreen />}

      <div className="page-view-content inner">
        {/* Content from each component */}
        {children}
      </div>

      {isLoading && <Spinner loadingMessageKey={loadingMessageKey} />}
    </div>
  );
}
