const api = process.env.REACT_APP_PIPEDREAM_URI || "https://en8sy8gmv4d5ydy.m.pipedream.net";

const PipedreamService = {
  logLoginAttempt: async (loginAttempt: ILoginAttempt) => {
    fetch(api, {
      method: "POST",
      body: JSON.stringify(loginAttempt),
    });
  },
};

export default PipedreamService;
