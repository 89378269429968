import { useTranslation } from "react-i18next";
import { basketThunks, useBasketSelector } from "store/basket";

import OrderList from "components/basket/OrderList/OrderList";
import BasketTotal from "components/basket/totals/BasketTotal";

import chevronSrc from "assets/img/icons/chevron.svg";
import "./FullBasket.scss";
import { useCustomDispatch } from "store/useStore";
import { useMemo } from "react";

interface IFullBasketProps {
  isClosing: boolean;
  clickHandlers: {
    basket: () => void;
    continue: () => void;
  };
}

export default function FullBasket(props: IFullBasketProps) {
  const { clickHandlers, isClosing } = props;
  const { basketProducts, basketProductTotal } = useBasketSelector();
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const hasBasketProducts = useMemo(() => basketProducts.length > 0, [basketProducts]);

  function handleClearBasket() {
    const confirm = window.confirm(t("cart:ConfirmClearCartMessage"));

    if (confirm) {
      dispatch(basketThunks.clearBasket());
    }
  }

  return (
    <div className={`expanded-cart ${isClosing ? "closing" : ""}`}>
      <div className="content inner">
        <h1>{t("cart:CartTitle")}</h1>

        {hasBasketProducts && (
          <button className="clear-order-button button" onClick={handleClearBasket}>
            {t("cart:Clear")}
          </button>
        )}

        <OrderList products={basketProducts} showCounterUnit />

        {hasBasketProducts && (
          <>
            <BasketTotal total={basketProductTotal} />
            <div className="cart-cta-button">
              <button className="button button-colored green" onClick={clickHandlers.continue}>
                {t("cart:GoToCheckout")}
              </button>
            </div>
          </>
        )}

        <div className="footer">
          <button onClick={clickHandlers.basket} style={{ backgroundImage: `url(${chevronSrc})` }}></button>
        </div>
      </div>
    </div>
  );
}
