export default function useAppInsights() {
  let operationId;

  if (window.appInsights && window.appInsights.context && window.appInsights.context.telemetryTrace) {
    operationId = window.appInsights.context.telemetryTrace.traceID;
  }

  return {
    operationId,
  }
}
