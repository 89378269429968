const getCheckoutSettings = () => {
  const defaultSettings = {
    terms: "https://bones.dk/handelsbetingelser",
    requiredGuestInformation: {
      name: true,
      phone: true,
      email: true,
    },
    enforceCapacity: true, // Determines if we prevent the user from choosing a timeslot without capacity
  };

  return defaultSettings;
};

export default getCheckoutSettings;
