import { IAvailableTimeDto } from "@crunchit/types";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import CheckoutService from "services/CheckoutService";
import { useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

export default function useAvailableTimes() {
  const { module } = useAppSelector();
  const { basketProducts } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutSession } = useCheckoutSelector();
  const { chosenDate } = checkoutSession;

  const dispatch = useCustomDispatch();

  const [availableTimes, setAvailableTimes] = useState<IAvailableTimeDto[]>([]);
  const [availableTimesLoaded, setAvailableTimesLoaded] = useState(false);

  const handleTimeChange = useCallback(
    async (chosenTime?: string) => {
      if (chosenTime) {
        dispatch(checkoutActions.setChosenTimeslot(chosenTime));
      } else {
        dispatch(checkoutActions.setChosenTimeslot(null));
      }
    },
    [dispatch]
  );

  const loadAvailableTimes = useCallback(
    async (chosenDate: string) => {
      dispatch(checkoutActions.setIsLoading({ isLoading: true }));

      try {
        const response = await CheckoutService.updatePickupTimes(module.checkoutInstanceId, {
          productionUnitId: module.productionUnitId,
          date: chosenDate,
          capacityInstanceId: module.capacityInstanceId,
          items: basketProducts,
        });

        if (response.isSuccess() && response.data.length) {
          const sortedAvailableTimes = response.data.sort((a: IAvailableTimeDto, b: IAvailableTimeDto) => moment(a.time).valueOf() - moment(b.time).valueOf());
          setAvailableTimes(sortedAvailableTimes);
        }
      } catch (error) {
        console.error(error);
      }

      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
      setAvailableTimesLoaded(true);
    },
    [module.productionUnitId, module.capacityInstanceId, module.checkoutInstanceId, basketProducts, dispatch]
  );

  useEffect(() => {
    if (checkout.id && chosenDate && !availableTimesLoaded) {
      loadAvailableTimes(chosenDate);
    }
  }, [checkout.id, chosenDate, availableTimesLoaded, loadAvailableTimes]);

  return {
    availableTimes,
    handleTimeChange,
  };
}
