import { IProductionUnitDto } from "@crunchit/types";
import { useTranslation } from "react-i18next";

import RestaurantInfo from "./RestaurantInfo";
import Page from "./page/Page";
import useAppInsights from "hooks/useAppInsights";
import "./ErrorMessage.scss";

interface IErrorMessageProps {
  productionUnit?: IProductionUnitDto | null;
  isErrorBoundary?: boolean;
  isBrowserError?: boolean;
  handleButtonClick: () => void;
}

// Error message view for generic error page + error boundary
export default function ErrorMessage(props: IErrorMessageProps) {
  const { productionUnit, isErrorBoundary, isBrowserError, handleButtonClick } = props;
  const { t } = useTranslation();
  const { operationId } = useAppInsights();

  return (
    <Page title={t("pagetitles:ErrorPage.Title")} subtitle={operationId ? operationId : undefined}>
      <div className="content-body error-message-content">
        <p className="status-page-text error-message-text">{isBrowserError ? t("errors:ErrorMessage.UnsupportedBrowser") : t("errors:ErrorMessage.TryAgain")}</p>

        {!isBrowserError && !isErrorBoundary && productionUnit && <RestaurantInfo productionUnit={productionUnit} />}

        <button className="button button-colored red" onClick={handleButtonClick}>
          {isErrorBoundary ? t("errors:Actions.TryAgain") : t("errors:Actions.BackToFrontpage")}
        </button>
      </div>
    </Page>
  );
}
