import Home from "views/Home";
import Category from "views/Category";
import Precheckout from "views/Precheckout";
import Order from "views/Order";
import Checkout from "views/Checkout";
import Confirmation from "views/Confirmation";
import Maintenance from "views/Maintenance";
import ErrorPage from "views/ErrorPage";
import Payment from "views/Payment";

const routes = [
  {
    path: "/",
    key: "root",
    Component: Home,
    setup: {
      dataOptions: { useBaskets: true, useCategories: true },
    },
  },
  {
    path: "/menu/:categoryId",
    key: "category",
    Component: Category,
    setup: {
      dataOptions: { useBaskets: true, useCategories: true },
    },
  },
  {
    path: "/precheckout",
    key: "precheckout",
    Component: Precheckout,
    setup: {
      layoutOptions: { hideBasket: true },
      dataOptions: { useBaskets: true, useCategories: true },
    },
  },
  {
    path: "/checkout",
    key: "checkout",
    Component: Checkout,
    setup: {
      layoutOptions: { hideBasket: true, hideSidebar: true, hideHeader: true },
      dataOptions: { useBaskets: true },
    },
  },
  {
    path: "/payment/:checkoutid",
    key: "payment",
    Component: Payment,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
  {
    path: "/order/:checkoutid",
    key: "order",
    Component: Order,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true, hidePauseScreen: true },
    },
  },
  {
    path: "/confirmation",
    key: "confirmation",
    Component: Confirmation,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
  {
    path: "/maintenance",
    key: "maintenance",
    Component: Maintenance,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
  {
    path: "/error",
    key: "error",
    Component: ErrorPage,
    setup: {
      layoutOptions: { isStatusPage: true, hideBasket: true, hideSidebar: true },
    },
  },
];

export default routes;
