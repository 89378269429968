import { IProductDto } from "@crunchit/types";
import { removeFromLocalStorage } from "@crunchit/utilities";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CheckoutBasket from "components/checkout/CheckoutBasket";
import GuestInfo from "components/checkout/GuestInfo";
import OrderComment from "components/checkout/OrderComment";
import Payment from "components/checkout/Payment/Payment";
import Timepicker from "components/checkout/Timepicker";
import Page from "components/ui/page/Page";
import CheckoutService from "services/CheckoutService";
import ProductService from "services/ProductService";
import { useAppSelector, appActions } from "store/app";
import { basketThunks, useBasketSelector } from "store/basket";
import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { siteStorageName } from "utils/constants/storage";
import { getCurrentCheckout } from "utils/helpers/checkout/session";

import "./Checkout.scss";

export default function Checkout() {
  const { appIsInitialized, module } = useAppSelector();
  const { basket, basketProducts, bagFeeProductId } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutInstanceSettings, checkoutSession } = useCheckoutSelector();
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const [bagFeeProduct, setBagFeeProduct] = useState<IProductDto>();

  // Handling bag fee add/remove
  useEffect(() => {
    if (bagFeeProductId && bagFeeProduct && basket.id && basketProducts.length > 0) {
      const shouldBeInCart = checkoutSession.bringOwnBagIsChecked === false;
      const bagFeeInBasket = basketProducts.find((p) => p.productId === bagFeeProductId);

      if (shouldBeInCart) {
        // We want the item in the cart - add if not already there
        if (!bagFeeInBasket) {
          dispatch(basketThunks.addBasketItem({ product: bagFeeProduct, count: 1 }));
        }
      } else {
        // Remove it from the cart if it's there
        if (bagFeeInBasket) {
          dispatch(basketThunks.removeBasketItem({ basketId: basket.id, basketProductId: bagFeeInBasket.id }));
        }
      }
    }
  }, [basket.id, basketProducts, checkoutSession.bringOwnBagIsChecked, bagFeeProductId, bagFeeProduct, dispatch]);

  const handleError = useCallback(
    (error: unknown) => {
      dispatch(appActions.setError({ message: "Checkout error", error }));
    },
    [dispatch]
  );

  const handleCheckoutInitializationError = useCallback(() => {
    removeFromLocalStorage(siteStorageName, "checkoutId");
    dispatch(checkoutActions.setCheckout(null));
    dispatch(checkoutActions.setIsLoading({ isLoading: false }));
  }, [dispatch]);

  const initializeComponent = useCallback(async () => {
    dispatch(checkoutActions.resetCheckoutSession());

    try {
      const checkout = await getCurrentCheckout({ moduleId: module.id, checkoutInstanceId: module.checkoutInstanceId });

      if (!checkout) {
        throw new Error("Unable to get initial checkout");
      }

      dispatch(checkoutActions.setCheckout(checkout));
      dispatch(checkoutActions.setChosenDate(moment().format("YYYY-MM-DD")));
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    } catch (error) {
      handleCheckoutInitializationError();
      handleError(error instanceof Error ? error.message : error);
    }
  }, [module, handleCheckoutInitializationError, handleError, dispatch]);

  // Initial load of instance settings
  useEffect(() => {
    const setupCheckoutInstanceSettings = async (checkoutInstanceId: string | null) => {
      if (!checkoutInstanceId) {
        handleCheckoutInitializationError();
        return;
      }

      try {
        const checkoutInstanceSettingsResponse = await CheckoutService.getCheckoutInstanceSettings(checkoutInstanceId);

        if (!checkoutInstanceSettingsResponse.isSuccess()) {
          throw new Error("Unable to get checkout instance settings");
        }

        dispatch(checkoutActions.setCheckoutInstanceSettings(checkoutInstanceSettingsResponse.data));
      } catch (error) {
        handleCheckoutInitializationError();
        handleError(error instanceof Error ? error.message : error);
      }
    };

    if (appIsInitialized && !checkoutInstanceSettings.instanceId) {
      setupCheckoutInstanceSettings(module.checkoutInstanceId);
    }
  }, [appIsInitialized, module.checkoutInstanceId, checkoutInstanceSettings.instanceId, handleCheckoutInitializationError, handleError, dispatch]);

  // Initial load of bag fee
  useEffect(() => {
    let bagFeeIsFetching = false;

    const setupBagfee = async (bagFeeProductId: number, productInstanceId: string) => {
      bagFeeIsFetching = true;

      const bagFeeProductResponse = await ProductService.getProductById(bagFeeProductId, productInstanceId);

      if (bagFeeProductResponse.isSuccess()) {
        setBagFeeProduct(bagFeeProductResponse.data);
      }

      bagFeeIsFetching = false;
    };

    if (appIsInitialized && bagFeeProductId && !bagFeeProduct && !bagFeeIsFetching) {
      setupBagfee(bagFeeProductId, module.productInstanceId);
    }
  }, [appIsInitialized, module.productInstanceId, checkoutInstanceSettings.instanceId, bagFeeProductId, bagFeeProduct]);

  // Initialize
  useEffect(() => {
    if (appIsInitialized) {
      initializeComponent();
    }
  }, [appIsInitialized, initializeComponent]);

  // A list of components, to have automatic section index
  const getCheckoutSections = () => {
    const indexOffset = 0;

    if (basketProducts.length === 0) {
      return { sections: [CheckoutBasket], indexOffset };
    }

    const sections = [CheckoutBasket, OrderComment, GuestInfo, Timepicker, Payment];

    return { sections, indexOffset };
  };

  const checkoutSections = getCheckoutSections();

  return (
    <>
      <Link to="/">
        <span className="left-icon icon"></span>
        {t("common:Navigation.GoBack")}
      </Link>

      <div className="content-wrapper">
        <Page title={t("pagetitles:CheckoutPage.Title")}>
          <div className={`content-body checkout-content ${checkoutSession.showValidationErrors ? "show-validation-errors" : ""}`}>
            {checkout && (
              <div className="content-sections">
                {checkoutSections.sections.map((CheckoutSection, index) => (
                  <CheckoutSection key={index} index={index + checkoutSections.indexOffset} />
                ))}
              </div>
            )}
          </div>
        </Page>
      </div>
    </>
  );
}
