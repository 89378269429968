import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useInputValidation } from "hooks/useInputValidation";
import CheckoutService from "services/CheckoutService";
import { appActions, useAppSelector } from "store/app";
import { useBasketSelector } from "store/basket";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { addCheckoutBasket, addCheckoutDiscount, addCheckoutGiftcard, addCheckoutGuestInfo, addCheckoutPayment } from "utils/helpers/checkout/update";

export function usePayment() {
  const { module } = useAppSelector();
  const { basket } = useBasketSelector({ allowCartFees: true });
  const { checkout, checkoutSession, getCheckoutPayments } = useCheckoutSelector();

  const { isDelivery, chosenTimeslot, guestInformation, discount, giftcard } = checkoutSession;

  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  const { getInputError } = useInputValidation();

  let [termsAccepted, setTermsAccepted] = useState(false);
  let [showRestaurantOfflineError, setShowRestaurantOfflineError] = useState(false);
  let [invalidFields, setInvalidFields] = useState<string[]>([]);

  const payments = useMemo(() => getCheckoutPayments(), [getCheckoutPayments]);

  const resetPaymentErrors = useCallback(() => {
    setShowRestaurantOfflineError(false);
    setInvalidFields([]);
  }, []);

  const withFinalCheckoutData = useCallback(async () => {
    if (!chosenTimeslot) {
      throw new Error("No timeslot at payment");
    } else {
      let updatedCheckout = checkout;

      // Add the basket again
      await addCheckoutBasket(checkout.id, basket.id); // Does not return an updated checkout

      // Add the guestInfo
      updatedCheckout = await addCheckoutGuestInfo(checkout, isDelivery, guestInformation, chosenTimeslot);

      // Check discounts match our input (remove, replace or add)
      updatedCheckout = await addCheckoutDiscount(checkout, discount);

      // Check giftcards match our input (remove, replace or add)
      updatedCheckout = await addCheckoutGiftcard(checkout, { giftcard, payments });

      return updatedCheckout;
    }
  }, [checkout, basket.id, isDelivery, guestInformation, chosenTimeslot, discount, giftcard, payments]);

  const getInvalidFields = useCallback(() => {
    let updatedInvalidFields = [];

    const { name = "", phone = "", email = "" } = guestInformation;

    updatedInvalidFields.push(getInputError("name", name, "errors:MissingFields.Name"));
    updatedInvalidFields.push(getInputError("phone", phone, "errors:MissingFields.Phone"));
    updatedInvalidFields.push(getInputError("email", email, "errors:MissingFields.Email"));

    return updatedInvalidFields.filter((m) => m !== "");
  }, [guestInformation, getInputError]);

  const validateCheckout = useCallback(async () => {
    resetPaymentErrors();

    dispatch(checkoutActions.setIsLoading({ isLoading: true, loadingMessageKey: "checkout:SpinnerMessage.ValidatingOrder" }));

    // Check for user input errors
    const updatedInvalidFields = getInvalidFields();

    if (updatedInvalidFields.length > 0) {
      setInvalidFields(updatedInvalidFields);
      dispatch(checkoutActions.setShowValidationErrors(true));
      return false;
    }

    try {
      // Final updates for the checkout
      const updatedCheckout = await withFinalCheckoutData();

      // Order validation / Restaurant online (Simphony) check
      const orderValidationResponse = await CheckoutService.validateOrder(checkout.id, module.productionUnitId);

      // Request only returns a response if location is offline and we get an error
      if (!orderValidationResponse.isSuccess()) {
        setShowRestaurantOfflineError(true);
        throw new Error("Restaurant is offline");
      }

      dispatch(checkoutActions.setCheckout(updatedCheckout));
      return true;
    } catch (error) {
      return false;
    }
  }, [checkout.id, module.productionUnitId, resetPaymentErrors, withFinalCheckoutData, getInvalidFields, dispatch]);

  const handlePayment = useCallback(async () => {
    if (!guestInformation.email) {
      throw Error("Missing email is required for checkout and payment");
    }

    const currentUrl = window.location.href;
    const baseUrl = currentUrl.replace("/checkout", "");

    const continueUrl = `${baseUrl}/order/${checkout.id}`;
    const cancelUrl = currentUrl;

    const receiptEmail = encodeURIComponent(guestInformation.email);

    // Payment link will be fetched, which takes longer - so we show an updated loading message
    dispatch(checkoutActions.setIsLoading({ isLoading: true, loadingMessageKey: "checkout:SpinnerMessage.StartingPayment" }));

    try {
      const checkoutPayment = await addCheckoutPayment(checkout.id, { moduleId: module.id, cancelUrl, continueUrl, receiptEmail });

      if (!checkoutPayment) {
        throw new Error("No checkout payment");
      }

      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
      redirect(`/payment/${checkout.id}`);
    } catch (error) {
      dispatch(appActions.setError({ message: "Unable to get payment link", error }));
    }
  }, [checkout.id, module.id, dispatch, redirect, guestInformation.email]);

  return {
    termsAccepted,
    setTermsAccepted,
    showRestaurantOfflineError,
    invalidFields,
    validateCheckout,
    handlePayment,
  };
}
