import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "components/ui/forms";
import PipedreamService from "services/PipedreamService";
import { appActions, useAppSelector } from "store/app";
import { useCustomDispatch } from "store/useStore";
import { sessionIsAuthenticatedName } from "utils/constants/storage";

import "./Login.scss";

export default function Login() {
  const { productionUnit } = useAppSelector();
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);

  function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (!username || !password || !productionUnit) {
      return;
    }

    const productionUnitId = productionUnit.id.toString();
    const passwordApproved = password === productionUnitId;

    PipedreamService.logLoginAttempt({
      url: window.location.href,
      passwordApproved,
      productionUnitId,
      username,
    });

    if (passwordApproved) {
      sessionStorage.setItem(sessionIsAuthenticatedName, JSON.stringify(true));
      dispatch(appActions.setSessionIsAuthenticated(true));
    } else {
      setShowPasswordError(true);
    }
  }

  useEffect(() => {
    setShowPasswordError(false);
  }, [username, password]);

  return (
    <div className="content-body login-content">
      <h1>{t("login:Title")}</h1>

      <form onSubmit={onSubmit}>
        <Input inputValue={username} overrideParameters={{ name: "username", type: "text" }} label={t("login:Username")} inputChanged={({ value }) => setUsername(value)} />
        <Input inputValue={password} overrideParameters={{ name: "password", type: "password" }} label={t("login:Password")} inputChanged={({ value }) => setPassword(value)} />

        {showPasswordError && <p className="error">{t("errors:Login.InvalidPassword")}</p>}

        <button className="button button-colored red" type="submit">
          {t("login:Action")}
        </button>
      </form>
    </div>
  );
}
