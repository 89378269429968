import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes, Navigate, useLocation } from "react-router";

import NotFound from "./views/NotFound";
import View from "./views/View";
import withInitializedData from "./hoc/withInitializedData";
import routes from "routes";
import { sessionIsAuthenticatedName } from "utils/constants/storage";
import { useCustomDispatch } from "store/useStore";
import { appActions, appThunks, useAppSelector } from "store/app";
import Login from "views/Login";

export default function App() {
  const { appIsAuthenticated, appIsInitialized, sessionIsAuthenticated } = useAppSelector();

  const location = useLocation();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  // Reloads the page if a mobile browser pressed back and is serving from cache
  useEffect(() => {
    window.onpageshow = function (event) {
      const performanceType = window.performance && window.performance.navigation && window.performance.navigation.type ? window.performance.navigation.type : undefined;

      // eslint-disable-next-line
      if (event.persisted || performanceType == 2) {
        window.location.reload();
      }
    };
  }, []);

  // Handle scroll when a new page is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Authenticate and init app
  useEffect(() => {
    if (!appIsAuthenticated) {
      dispatch(appThunks.authenticate());
    }

    if (appIsAuthenticated && !appIsInitialized) {
      dispatch(appThunks.initialize());
    }
  }, [appIsAuthenticated, appIsInitialized, dispatch]);

  // Set session authentication status and redirect if necessary
  useEffect(() => {
    const authenticatedSessionValue = sessionStorage.getItem(sessionIsAuthenticatedName);
    const sessionIsAuthenticated = authenticatedSessionValue && JSON.parse(authenticatedSessionValue);

    if (sessionIsAuthenticated) {
      dispatch(appActions.setSessionIsAuthenticated(true));
    } else if (location.pathname !== "/") {
      redirect("/");
    }
  }, [dispatch, location.pathname, redirect]);

  // Wrapper component - ensures data is fetched before rendering the main component
  let ViewComponent = useMemo(() => withInitializedData(View), []);

  if (!sessionIsAuthenticated) {
    return <Login />;
  }

  return (
    <Routes>
      {routes.map(({ key, path, setup, Component }) => {
        return (
          <Route
            key={key}
            path={path}
            element={
              <ViewComponent pageKey={key} {...setup}>
                <Component />
              </ViewComponent>
            }
          />
        );
      })}

      <Route path={"/notfound"} element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/notfound" />} />
    </Routes>
  );
}
