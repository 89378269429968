import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PaymentError from "./PaymentError";
import { usePayment } from "./usePayment";
import Terms from "./Terms";
import InlineRestaurantInfo from "components/ui/InlineRestaurantInfo";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";

import "./Payment.scss";

export default function Payment() {
  const { t } = useTranslation();
  const { isLoading: checkoutIsLoading } = useCheckoutSelector();
  const dispatch = useCustomDispatch();

  const { termsAccepted, setTermsAccepted, showRestaurantOfflineError, invalidFields, validateCheckout, handlePayment } = usePayment();

  const paymentIsDisabled = useMemo(() => checkoutIsLoading || !termsAccepted, [checkoutIsLoading, termsAccepted]);

  async function handlePaymentClick() {
    const validated = await validateCheckout();

    if (validated) {
      await handlePayment();
    } else {
      dispatch(checkoutActions.setIsLoading({ isLoading: false }));
    }
  }

  return (
    <div className="payment-section">
      <div className="finalize-buttons-body">
        <Terms checked={termsAccepted} updateChecked={(checked: boolean) => setTermsAccepted(checked)} />

        <button onClick={handlePaymentClick} className="button button-colored red" disabled={paymentIsDisabled}>
          {t("checkout:Payment.Buttons.PlaceOrder")}
        </button>
      </div>

      <PaymentError showRestaurantOfflineError={showRestaurantOfflineError} invalidFields={invalidFields} />

      <div className="paymentcards"></div>
      
      <InlineRestaurantInfo />
    </div>
  );
}
