import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./PaymentError.scss";

interface IPaymentErrorProps {
  showRestaurantOfflineError: boolean;
  invalidFields: string[];
}

export default function PaymentError(props: IPaymentErrorProps) {
  const { showRestaurantOfflineError, invalidFields } = props;
  const { t } = useTranslation();

  const invalidFieldsErrorMessage = useMemo(() => {
    if (!invalidFields.length) {
      return t("errors:MissingFields.Generic");
    }

    let errorMsg = "";

    for (let i = 0; i < invalidFields.length; i++) {
      const invalidField = invalidFields[i];
      
      if (i > 0) {
        // This is the last one, but not the only one
        if (!invalidFields[i + 1]) {
          errorMsg += " & ";
        } else {
          errorMsg += ", ";
        }
      }

      errorMsg += t(invalidField);
    }

    return errorMsg;
  }, [t, invalidFields]);

  return (
    <div className="checkout-error-container">
      {invalidFields.length > 0 && (
        <div className="checkout-error">
          <p>{invalidFieldsErrorMessage}</p>
        </div>
      )}

      {showRestaurantOfflineError && (
        <div className="checkout-error">
          <p>{t("errors:Payment.RestaurantOffline")}</p>
        </div>
      )}
    </div>
  );
}
