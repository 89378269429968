import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import "./PauseScreen.scss";

export default function PauseScreen() {
  const { appSettings } = useAppSelector();
  const { t } = useTranslation();

  return (
    <aside className="pause-screen">
      <img className="logo" src={appSettings.logo} loading="lazy" alt="logo" />
      <h1>{t("pausescreen:Title")}</h1>
      <p>{t("pausescreen:CtaText")}</p>
    </aside>
  );
}
