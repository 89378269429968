import { ICheckoutDto } from "@crunchit/types";
import { getFromLocalStorage } from "@crunchit/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICriticalError } from "models/store";
import CheckoutService from "services/CheckoutService";
import { siteStorageName } from "utils/constants/storage";

type Rejection = { rejectValue: ICriticalError };

/* Async reducers */

export const loadCheckout = createAsyncThunk<ICheckoutDto | null, void, Rejection>("checkout/loadCheckout", async (args, { rejectWithValue }) => {
  const existingCheckoutId = getFromLocalStorage(siteStorageName, "checkoutId");

  if (!existingCheckoutId) {
    return null;
  }

  try {
    const existingCheckoutResponse = await CheckoutService.getCheckoutById(existingCheckoutId);

    if (!existingCheckoutResponse.isSuccess()) {
      return rejectWithValue({ message: `Unable to get checkout by id '${existingCheckoutId}'`, error: existingCheckoutResponse.errors });
    }

    return existingCheckoutResponse.data;
  } catch (error) {
    return rejectWithValue({ message: `Unable to get checkout by id '${existingCheckoutId}'`, error: error instanceof Error ? error.message : error });
  }
});

const thunks = {
  loadCheckout,
};

export default thunks;
