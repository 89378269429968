import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/app";

import "./InlineRestaurantInfo.scss";

export default function InlineRestaurantInfo() {
  const { productionUnit } = useAppSelector();
  const { t } = useTranslation();

  const streetAndCity = useMemo(() => {
    if (productionUnit && productionUnit.address) {
      return productionUnit.address.split(",");
    }
  }, [productionUnit]);

  if (!productionUnit) {
    return <></>;
  }

  return (
    <p className="restaurant-info">
      <span>{productionUnit.name},</span>
      {productionUnit.phone && (
        <span className="restaurant-phone">
          {t("checkout:Pickup.TelephoneNumber")}:{productionUnit.phone},
        </span>
      )}
      {streetAndCity && (
        <span>
          {streetAndCity[0]}, {productionUnit.zipCode} {streetAndCity[1]}
        </span>
      )}
    </p>
  );
}
