import cart from "./cart.json";
import checkout from "./checkout.json";
import common from "./common.json";
import confirmation from "./confirmation.json";
import errors from "./errors.json";
import pagetitles from "./pagetitles.json";
import pausescreen from "./pausescreen.json";
import payment from "./payment.json";
import precheckout from "./precheckout.json";
import products from "./products.json";

export const en = {
  cart,
  checkout,
  common,
  confirmation,
  errors,
  pausescreen,
  pagetitles,
  payment,
  precheckout,
  products,
};
