import { useState } from "react";
import { useTranslation } from "react-i18next";

import TermsIframe from "./TermsIframe";
import Checkbox from "components/ui/forms/Checkbox";
import "./Terms.scss";

interface ITermsProps {
  checked: boolean;
  updateChecked: (updatedValue: boolean) => void;
}

export default function Terms(props: ITermsProps) {
  const { checked, updateChecked } = props;
  const { t } = useTranslation();

  const [showTerms, setShowTerms] = useState(false);

  function handleTermsClick(event: React.MouseEvent) {
    event.preventDefault();
    setShowTerms(true);
  }

  return (
    <div className="terms-checkbox">
      {showTerms && <TermsIframe handleDismiss={() => setShowTerms(false)} />}

      <Checkbox
        checked={checked}
        label={
          <span className="checkbox-label">
            {t("checkout:Payment.FirstTermsText")} <button className="terms-trigger-btn" onClick={handleTermsClick}>{t("checkout:Payment.TermsTextLink")}</button>{t("checkout:Payment.SecondTermsText")}
          </span>
        }
        handleInputChange={() => updateChecked(!checked)}
      />
    </div>
  );
}
