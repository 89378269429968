import { PaymentState } from "@crunchit/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import Page from "components/ui/page/Page";
import useAppInsights from "hooks/useAppInsights";
import usePaymentActions from "hooks/usePaymentActions";
import PaymentService from "services/PaymentService";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";
import { customInsightsEvent } from "utils/helpers/errors";

import "./Payment.scss";

export default function Payment() {
  const { isCancellingPayment } = useCheckoutSelector();
  const { t } = useTranslation();
  const { checkoutid } = useParams();
  const { cancelPayment } = usePaymentActions();
  const { operationId } = useAppInsights();

  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  const [statusCheckInterval, setStatusCheckInterval] = useState<NodeJS.Timeout | null>(null);
  const [paymentState, setPaymentState] = useState(PaymentState.CREATED);

  const isPending = useMemo(() => paymentState === PaymentState.CREATED, [paymentState]);
  const hasFailed = useMemo(() => paymentState === PaymentState.FAILED, [paymentState]);

  const handleCancelPayment = useCallback(async () => {
    if (isCancellingPayment) {
      return;
    }

    await cancelPayment();
    setPaymentState(PaymentState.CANCELLED);
  }, [isCancellingPayment, cancelPayment]);

  const updatePayment = useCallback(async () => {
    if (!checkoutid) {
      throw new Error("No checkout id");
    }

    try {
      const response = await PaymentService.getPaymentByCheckoutId(checkoutid);

      if (!response.isSuccess()) {
        throw new Error();
      }

      dispatch(checkoutActions.setPayment(response.data));

      if (response.data.state > PaymentState.FAILED) {
        setPaymentState(PaymentState.AUTHORIZED);
        setTimeout(() => redirect(`/order/${checkoutid}`), 3000);
        return;
      }

      if (response.data.state === PaymentState.FAILED) {
        throw new Error();
      }

      setPaymentState(response.data.state);
    } catch (error) {
      setPaymentState(PaymentState.FAILED);
    }
  }, [checkoutid, redirect, dispatch]);

  useEffect(() => {
    if (hasFailed && checkoutid) {
      customInsightsEvent("PaymentError", { CheckoutId: checkoutid });
    }
  }, [hasFailed, checkoutid]);

  useEffect(() => {
    updatePayment();
  }, [updatePayment]);

  useEffect(() => {
    if (isPending && !statusCheckInterval) {
      const newInterval = setInterval(updatePayment, 2000);
      setStatusCheckInterval(newInterval);
    }
    if (!isPending && statusCheckInterval) {
      clearInterval(statusCheckInterval);
    }
    return () => {
      if (statusCheckInterval) {
        clearInterval(statusCheckInterval);
      }
    };
  }, [isPending, statusCheckInterval, updatePayment]);

  function Button() {
    const className = "button button-colored red";

    switch (paymentState) {
      case PaymentState.CREATED:
        return (
          <button className={className} onClick={handleCancelPayment} disabled={isCancellingPayment}>
            {t("payment:CancelPayment")}
          </button>
        );

      case PaymentState.CANCELLED:
        return (
          <button className={className} onClick={() => redirect("/")}>
            {t("payment:GoBack")}
          </button>
        );

      case PaymentState.FAILED:
        return (
          <button className={className} onClick={() => redirect("/checkout")}>
            {t("payment:GoBack")}
          </button>
        );

      default:
        return <></>;
    }
  }

  return (
    <Page title={t(`payment:Titles.${PaymentState[paymentState].toLowerCase()}`)} subtitle={hasFailed && operationId ? operationId : undefined}>
      {isPending && <p>{t("payment:Subtitle")}</p>}
      {Button()}
    </Page>
  );
}
