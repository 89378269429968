import { ITranslation } from "@crunchit/types";
import { useMemo } from "react";
import Counter from "components/ui/Counter";
import { useTranslation } from "react-i18next";
import useTranslationText from "hooks/useTranslationText";

interface IModifierViewProps {
  productName: ITranslation;
  price: number;
  quantity: number;
  handleChange: (updatedCount: number, increased: boolean) => void;
  index?: number;
}

export default function QuantityModifier(props: IModifierViewProps) {
  const { quantity, index, productName, price, handleChange } = props;

  const { t } = useTranslation();

  const { getTranslationText } = useTranslationText();

  const translatedProductName = getTranslationText(productName);

  const disableDecrease = useMemo(() => {
    return quantity === 1;
  }, [quantity]);

  const totalModifierPrice = useMemo(() => {
    return price * quantity;
  }, [quantity, price]);

  return (
    <article className="modifier quantity-modifier collapsible-section expanded">
      <h1 className="modifier-title">
        <span className="modifier-counter">0{index || 1}</span>
        {t("products:HowMany")}
      </h1>

      <ul className="choice-list collapsible-content">
        <li className="choice active">
          <p className="choice-name">{translatedProductName}</p>

          <Counter count={quantity} disableDecrease={disableDecrease} handleChange={handleChange} />
        </li>
      </ul>

      <footer>
        <p className="extra-choice-price">{`${totalModifierPrice}`}</p>
      </footer>
    </article>
  );
}
