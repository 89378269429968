import { CheckoutState } from "@crunchit/types";
import { clearAllLocalStorage } from "@crunchit/utilities";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector, appActions } from "store/app";
import { useCheckoutSelector, checkoutActions } from "store/checkout";
import { basketActions } from "store/basket";
import { useCustomDispatch } from "store/useStore";

import useFinalOrder from "hooks/useFinalOrder";

import RestaurantInfo from "components/ui/RestaurantInfo";
import Page from "components/ui/page/Page";
import { siteStorageName } from "utils/constants/storage";

import "./Confirmation.scss";

export default function Confirmation() {
  const { t } = useTranslation();
  const { appIsInitialized, token, productionUnit } = useAppSelector();
  const { checkout } = useCheckoutSelector();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();

  let [orderId, setOrderId] = useState<string>();

  const { orderError, orderProperties } = useFinalOrder(checkout, productionUnit, orderId);

  useEffect(() => {
    if (appIsInitialized && !orderProperties) {
      if (checkout && checkout.id) {
        if (checkout.state === CheckoutState.DONE && checkout.orderId) {
          setOrderId(checkout.orderId);
        } else {
          // Checkout not successful
          dispatch(appActions.setError({ message: "Checkout failed", error: checkout }));
        }
      } else {
        // No checkout in the session - likely a navigation error
        redirect("/");
      }
    }
  }, [appIsInitialized, token, checkout, orderProperties, dispatch, redirect, productionUnit]);

  useEffect(() => {
    if (orderError) {
      dispatch(appActions.setError({ message: "Order failed", error: orderError }));
    }
  }, [orderError, dispatch]);

  // Delete basket and checkout in localStorage and redux after fetching order successfully
  useEffect(() => {
    if (orderProperties) {
      dispatch(checkoutActions.setCheckout(null));
      dispatch(checkoutActions.setPayment(null));
      dispatch(basketActions.setBasket(null));
      clearAllLocalStorage(siteStorageName);
    }
  }, [orderProperties, dispatch]);

  let receiptMessageText = "";

  if (orderProperties && orderProperties.receiptMessageType) {
    const messageTypeText = orderProperties.receiptMessageType === "SMS" ? t("common:Communication.SMS") : t("common:Communication.Email");
    receiptMessageText = t("confirmation:ReceiptMessage", { messageType: messageTypeText.toLowerCase() });
  }

  return (
    <Page title={t("pagetitles:ConfirmationPage.Title")}>
      {orderProperties && (
        <div className="content-body confirmation-content">
          <p className="status-page-text confirmation-text-title">{t("pagetitles:ConfirmationPage.Subtitle")}</p>

          {productionUnit && <RestaurantInfo productionUnit={productionUnit} />}

          <p className="status-page-text">
            {t("confirmation:OrderNumber")}: {orderProperties.receiptNumber}
          </p>

          {receiptMessageText !== "" && (
            <p className="status-page-text email-confirmation-text">
              <strong>{receiptMessageText}</strong>
            </p>
          )}

          <footer>
            <button className="button button-colored red" onClick={() => redirect("/")}>
              {t("confirmation:ReturnToStartButton")}
            </button>
          </footer>
        </div>
      )}
    </Page>
  );
}
