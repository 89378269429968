import { useCallback, useEffect, useState } from "react";

export default function useUserActivity(timeoutMs = 2 * 60 * 1000) {
  const [latestActivtiy, setLatestActivity] = useState(Date.now());
  const [userIsInactive, setUserIsInactive] = useState(false);

  const checkForInactivity = useCallback(() => {
    const now = Date.now();

    if (now - timeoutMs > latestActivtiy) {
      setUserIsInactive(true);
    }
  }, [latestActivtiy, timeoutMs]);

  const onActivity = useCallback(() => {
    setLatestActivity(Date.now());
    setUserIsInactive(false);
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", onActivity);
    document.addEventListener("keypress", onActivity);

    return () => {
      document.removeEventListener("mousemove", onActivity);
      document.removeEventListener("keypress", onActivity);
    };
  }, [onActivity]);

  useEffect(() => {
    const interval = setInterval(checkForInactivity, timeoutMs);

    return () => {
      clearInterval(interval);
    };
  }, [checkForInactivity, timeoutMs]);

  return {
    userIsInactive,
  };
}
