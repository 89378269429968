import { IPaymentDto, PaymentType } from "@crunchit/types";
import { formatPrice } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./ItemizedTotal.scss";

interface IItemizedTotalProps {
  orderTotal: number;

  payments?: Partial<IPaymentDto>[];

  deliveryFee?: number;
  test?: number;

  isDiscountEnabled?: boolean;
  discountAmount?: number;
}

export default function ItemizedTotal(props: IItemizedTotalProps) {
  const { orderTotal = 0, payments = [] } = props;

  const { t } = useTranslation();

  let paidAmount = useMemo(() => {
    let paid = 0;
    if (payments.length) {
      paid = payments.reduce((accumulated, currentPayment) => {
        const { type, amount = 0 } = currentPayment;
        return accumulated + (type && type === PaymentType.UNPAID ? 0 : amount);
      }, 0);
    }
    return paid;
  }, [payments]);

  return (
    <div className="itemized-total-section">
      <div className="final-total basket-total">
        <h2 className="total-title">{t("cart:OrderSummary.Leftover")}</h2>
        <h2 className="total-amount">
          {formatPrice(orderTotal - paidAmount)} <span className="currency">{t("common:Currency")}</span>
        </h2>
      </div>
    </div>
  );
}
