import PaymentService from "services/PaymentService";
import { checkoutActions, useCheckoutSelector } from "store/checkout";
import { useCustomDispatch } from "store/useStore";


export default function usePaymentActions() {
  const { payment, isCancellingPayment } = useCheckoutSelector();

  const dispatch = useCustomDispatch();

  async function cancelPayment() {
    if (isCancellingPayment || !payment) {
      return null;
    }
  
    dispatch(checkoutActions.setIsCancellingPayment(true));
  
    try {
      const cancelPaymentResponse = await PaymentService.cancelPayment(payment.id);
  
      if (!cancelPaymentResponse.isSuccess()) {
        throw new Error();
      }
  
      return cancelPaymentResponse.data;
    } catch (error) {
      return null;
    } finally {
      dispatch(checkoutActions.setIsCancellingPayment(false));
    }
  }

  return {
    cancelPayment,
  }
}
