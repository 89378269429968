import { CheckoutState, ICheckoutDto } from "@crunchit/types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import CheckoutService from "services/CheckoutService";
import { useCustomDispatch } from "store/useStore";
import { useAppSelector, appActions } from "store/app";
import { checkoutActions } from "store/checkout";
import { customInsightsEvent } from "utils/helpers/errors";
import Page from "components/ui/page/Page";

export default function Order() {
  const { t } = useTranslation();
  const dispatch = useCustomDispatch();
  const redirect = useNavigate();
  const { appIsInitialized, appIsAuthenticated } = useAppSelector();
  const { checkoutid } = useParams();
  let [isPaymentOrder, setIsPaymentOrder] = useState(false);

  const handleError = useCallback(
    (error: unknown) => {
      if (checkoutid) {
        customInsightsEvent("CheckoutError", { CheckoutId: checkoutid });
      }

      dispatch(appActions.setError({ message: "Order error", error }));
    },
    [checkoutid, dispatch]
  );

  const handleCheckoutIsDone = useCallback(
    (finalCheckout: ICheckoutDto) => {
      dispatch(checkoutActions.setCheckout(finalCheckout));
      redirect("/confirmation");
    },
    [dispatch, redirect]
  );

  // Sending the order here if there's no payment
  const handlePickupOrder = useCallback(
    async (checkout: ICheckoutDto) => {
      try {
        const finalCheckoutResponse = await CheckoutService.finalizeCheckout(checkout.id);

        if (!finalCheckoutResponse.isSuccess()) {
          throw new Error(`Unable to finalize checkout with id '${checkout.id}'`);
        }

        const finalCheckout = finalCheckoutResponse.data;

        if (finalCheckout.state === CheckoutState.DONE) {
          handleCheckoutIsDone(finalCheckout);
        } else {
          throw new Error(`Checkout with id '${checkout.id}' returned finalized state ${finalCheckout.state}`);
        }
      } catch (error) {
        handleError(error instanceof Error ? error.message : error);
      }
    },
    [handleCheckoutIsDone, handleError]
  );

  // Interval to fetch checkout if payment order
  useEffect(() => {
    let timer: any;

    if (!appIsInitialized || !isPaymentOrder) {
      return;
    }

    timer = setInterval(async () => {
      if (!checkoutid) {
        return;
      }

      try {
        const updatedCheckoutResponse = await CheckoutService.getCheckoutById(checkoutid);

        if (updatedCheckoutResponse.isSuccess()) {
          const checkout = updatedCheckoutResponse.data;

          if (checkout.state === CheckoutState.ERROR) {
            throw new Error("Updated checkout is in error state");
          }

          if (checkout.state === CheckoutState.DONE) {
            handleCheckoutIsDone(checkout);
          }
        }
      } catch (error) {
        handleError(error instanceof Error ? error.message : error);
      }
    }, 2000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [appIsInitialized, isPaymentOrder, checkoutid, handleError, handleCheckoutIsDone]);

  let initializeComponent = useCallback(async () => {
    try {
      if (!checkoutid) {
        throw new Error("No checkout id to initialize Order with");
      }

      const initialCheckoutResponse = await CheckoutService.getCheckoutById(checkoutid);

      if (!initialCheckoutResponse.isSuccess()) {
        throw new Error(`Unable to get initial checkout with checkout id '${checkoutid}'`);
      }

      const checkout = initialCheckoutResponse.data;
      dispatch(checkoutActions.setCheckout(checkout));

      // If the checkout has an external payment reference, that means it will be handles in a callback so we fetch status in intervals
      if (checkout.payment && checkout.payment.paymentId) {
        setIsPaymentOrder(true); // This will trigger the interval fetch to start
        return;
      }

      if (checkout.state === CheckoutState.DONE) {
        throw new Error(`Checkout with id '${checkout.id}' has already been finalized`);
      }

      handlePickupOrder(checkout);
    } catch (error) {
      handleError(error instanceof Error ? error.message : error);
    }
  }, [checkoutid, dispatch, handleError, handlePickupOrder]);

  useEffect(() => {
    if (appIsAuthenticated) {
      initializeComponent();
    }
  }, [appIsAuthenticated, initializeComponent]);

  return (
    <div className="content-body order">
      <div className="spinner"></div>
      <Page title={t("pagetitles:OrderPage.Title")}></Page>
    </div>
  );
}
