import { CheckoutState, ICheckoutDto, IPaymentDto, PaymentType } from "@crunchit/types";
import { getFromLocalStorage, saveToLocalStorage } from "@crunchit/utilities";

import CheckoutService from "services/CheckoutService";
import { removeDeliveryInfo, removeDiscountCodes, removeGiftcards } from "./reset";
import { addCheckoutBasket } from "./update";
import { siteStorageName } from "utils/constants/storage";

// Additional checks for the checkout on initial session load

export async function getPreviousCheckout() {
  let previousCheckout: ICheckoutDto | null = null;

  const existingCheckoutId = getFromLocalStorage(siteStorageName, "checkoutId");
  if (existingCheckoutId) {
    const existingCheckoutResponse = await CheckoutService.getCheckoutById(existingCheckoutId);

    if (existingCheckoutResponse.isSuccess()) {
      previousCheckout = existingCheckoutResponse.data;
    }
  }
  return previousCheckout;
}

export async function getCurrentCheckout(data: { moduleId: string; checkoutInstanceId: string; previousCheckout?: ICheckoutDto | null }) {
  const { moduleId, checkoutInstanceId, previousCheckout } = data;

  let checkout;

  const basketId = getFromLocalStorage(siteStorageName, "basketId");

  if (previousCheckout) {
    // Validate that it's not finalized yet
    let checkoutIsValid = true; // Valid if we have one - now check contents

    if (previousCheckout.state === CheckoutState.DONE) {
      checkoutIsValid = false;
    }
    if (previousCheckout.orderId) {
      checkoutIsValid = false;
    }
    if (previousCheckout.payment) {
      checkoutIsValid = false;
    }
    if (previousCheckout.checkoutBaskets.length) {
      let isMatchingBasket = previousCheckout.checkoutBaskets.length === 1 && previousCheckout.checkoutBaskets[0].externalBasketId === basketId;
      if (!isMatchingBasket) {
        checkoutIsValid = false;
      }
    }
    if (checkoutIsValid) {
      checkout = Object.assign({}, previousCheckout);

      // Removing previous checkout session info if it exists
      checkout = await removeDiscountCodes(checkout);
      checkout = await removeGiftcards(checkout);
      checkout = await removeDeliveryInfo(checkout);
    }
  }

  // No existing (or it was used) - creating a new checkout id

  if (!checkout) {
    try {
      const checkoutResponse = await CheckoutService.createCheckout(checkoutInstanceId, moduleId);

      if (checkoutResponse.isSuccess() && checkoutResponse.data.id) {
        checkout = checkoutResponse.data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (checkout) {
    // Adding baskets
    if (basketId) {
      await addCheckoutBasket(checkout.id, basketId); // Does not return an updated checkout
    }

    saveToLocalStorage(siteStorageName, "checkoutId", checkout.id);
  }

  return checkout;
}

export const getGiftcardPaymentAmount = (payments?: Partial<IPaymentDto>[]) => {
  let amount = 0;

  if (payments && payments.length) {
    const giftcardPayment = payments.find((p) => p.type === PaymentType.GIFTCARD);
    if (giftcardPayment?.amount) {
      amount = giftcardPayment.amount;
    }
  }

  return amount;
};

export const getOustandingPayment = (basketProductTotal: number, deliveryFee: number, discountAmount: number, giftcardPaymentAmount: number) => {
  let finalBasketTotal = basketProductTotal;

  if (deliveryFee) {
    finalBasketTotal = finalBasketTotal + deliveryFee;
  }

  if (discountAmount) {
    finalBasketTotal = finalBasketTotal - discountAmount;
  }

  if (giftcardPaymentAmount) {
    finalBasketTotal = finalBasketTotal - giftcardPaymentAmount;
  }

  return finalBasketTotal;
};
