import { useEffect, useMemo } from "react";
import { useParams } from "react-router";

import { IBlockedProductDto, ICategoryDto, IProductDto } from "@crunchit/types";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";

import Sidebar from "components/navigation/Sidebar";
import Product from "components/menu/Product";

import useProducts from "hooks/useProducts";
import Page from "components/ui/page/Page";
import useTranslationText from "hooks/useTranslationText";

export default function Category() {
  const { appIsInitialized } = useAppSelector();
  const { context, categories, isLoading: menuDataIsLoading } = useMenuSelector();
  const { categoryId } = useParams();

  const { getTranslationText } = useTranslationText();

  let { blockedProducts, blockedChoices, labels, products, loadWarehouse, loadLabels, loadProducts } = useProducts();

  const category = useMemo(() => (categoryId ? categories.find((c: ICategoryDto) => c.id === Number(categoryId)) : undefined), [categoryId, categories]);

  const layoutClasses = useMemo(() => {
    let classes = "";

    if (products && products.length) {
      const firstProduct = products[0];

      if (firstProduct.modifiers.length === 0) {
        classes += "grid";
      }
    }

    return classes;
  }, [products]);

  useEffect(() => {
    if (appIsInitialized) {
      loadWarehouse();
      loadLabels();
    }
  }, [appIsInitialized, category, loadLabels, loadWarehouse]);

  useEffect(() => {
    if (category) {
      loadProducts(category);
    }
  }, [category, loadProducts]);

  if (!category) {
    return <></>;
  }

  const title = getTranslationText(category.name);
  const categoryDescription = getTranslationText(category.description);
  const subtitle = categoryDescription.length ? categoryDescription : undefined;

  return (
    <Page title={title} subtitle={subtitle}>
      <div className="content-body sidebar-body">
        <Sidebar />

        <div className={`product-list ${layoutClasses}`}>
          {!menuDataIsLoading &&
            context &&
            products.map(function (product: IProductDto) {
              return <Product key={product.id} product={product} context={context} labels={labels} isBlockedProduct={blockedProducts.find((b: IBlockedProductDto) => b.productId === product.id) !== undefined} blockedChoices={blockedChoices} />;
            })}
        </div>
      </div>
    </Page>
  );
}
