import { useCallback, useMemo, useState } from "react";
import { ICheckoutSectionProps } from "@crunchit/types";
import { useTranslation } from "react-i18next";

import Timepicker from "./Timepicker";
import useAvailableTimes from "./useAvailableTimes";
import SectionWrapper from "components/checkout/SectionWrapper";
import withClickOutside from "hoc/withClickOutside";
import "./Timepicker.scss";

export default function TimepickerContainer(props: ICheckoutSectionProps) {
  const { index } = props;
  const { t } = useTranslation();
  const { availableTimes, handleTimeChange } = useAvailableTimes();

  const [isOpen, setIsOpen] = useState(false);

  const handleTimeSelection = useCallback(
    (updatedTimeslot: string) => {
      handleTimeChange(updatedTimeslot);
      setIsOpen(false);
    },
    [handleTimeChange]
  );

  const TimepickerComponent = useMemo(() => withClickOutside(Timepicker, { clickHandler: () => setIsOpen(false) }), []);

  return (
    <SectionWrapper index={index} title={t("checkout:DeliveryMethod.PickupTitle")}>
      <TimepickerComponent isOpen={isOpen} availableTimes={availableTimes.filter((a) => a.isAvailable)} toggleIsOpen={() => setIsOpen(!isOpen)} handleTimeSelection={handleTimeSelection} />
    </SectionWrapper>
  );
}
