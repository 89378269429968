import { get, post, del } from "@crunchit/http-client";
import { IAvailableDateDto, IAvailableTimeDto, ICheckoutDto, ICheckoutDiscountDto, ICheckoutGuestInformationDto, ICheckoutInstanceSettingsDto, IPickupTimesRequestBody } from "@crunchit/types";
import { ICheckoutPaymentParams } from "models/payment";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_CHECKOUT_URI || `checkout.${getEnvironment()}crunchy.ninja/api`;

const CheckoutService = {
  getCheckoutById: async (id: string) => {
    return get<ICheckoutDto>(`${api}/checkout/${id}`, getToken());
  },
  getAvailablePickupDates: async (checkoutInstanceId: string, productionUnitId: number) => {
    return get<IAvailableDateDto[]>(`${api}/availableordertime/${checkoutInstanceId}/${productionUnitId}`, getToken());
  },
  getAvailableDeliveryDates: async (checkoutId: string) => {
    return get<IAvailableDateDto[]>(`${api}/checkout/${checkoutId}/delivery/dates`, getToken());
  },
  getAvailableDeliveryTimes: async (checkoutId: string) => {
    return get<IAvailableTimeDto[]>(`${api}/checkout/${checkoutId}/delivery/times`, getToken());
  },
  getCheckoutInstanceSettings: async (checkoutInstanceId: string) => {
    return get<ICheckoutInstanceSettingsDto>(`${api}/instancesetting/${checkoutInstanceId}`, getToken());
  },

  createCheckout: async (checkoutInstanceId: string, moduleId: string) => {
    return post<ICheckoutDto>(`${api}/checkout?instanceId=${checkoutInstanceId}&moduleId=${moduleId}`, {}, getToken());
  },
  addCheckoutDeliveryAddress: async (checkoutId: string, moduleId: string, address: string, zipCode: string) => {
    return post<ICheckoutDto>(`${api}/checkout/${checkoutId}/delivery/address?moduleId=${moduleId}&address=${address}&zipcode=${zipCode}`, {}, getToken());
  },
  addCheckoutDeliveryDate: async (checkoutId: string, dateTimeString: string) => {
    return post<undefined>(`${api}/checkout/${checkoutId}/delivery/date`, dateTimeString, getToken());
  },
  addCheckoutDeliveryTime: async (checkoutId: string, dateTimeString: string) => {
    return post<undefined>(`${api}/checkout/${checkoutId}/delivery/time`, dateTimeString, getToken());
  },
  addCheckoutBasket: async (checkoutId: string, basketId: string) => {
    return post<undefined>(`${api}/checkout/${checkoutId}/basket?basketId=${basketId}`, {}, getToken());
  },
  addCheckoutGuestInformation: async (checkoutId: string, guestInformation: ICheckoutGuestInformationDto) => {
    return post<ICheckoutDto>(`${api}/checkout/${checkoutId}/guestInformation`, guestInformation, getToken());
  },
  addCheckoutDiscount: async (checkoutId: string, discount: Partial<ICheckoutDiscountDto>) => {
    return post<ICheckoutDto>(`${api}/checkout/${checkoutId}/discount`, discount, getToken());
  },
  addCheckoutGiftcard: async (checkoutId: string, giftcardCode: string, amount: number) => {
    return post<ICheckoutDto>(`${api}/checkout/${checkoutId}/giftcard`, { giftcardCode, amount }, getToken());
  },
  addCheckoutPayment: async (checkoutId: string, params: ICheckoutPaymentParams) => {
    const { moduleId, cancelUrl, continueUrl, paymentMethods, receiptEmail, isTest } = params;

    let query = `?moduleId=${moduleId}&continuationUrl=${continueUrl}&cancellationUrl=${cancelUrl}`;

    if (paymentMethods) {
      query += `&paymentMethods=${paymentMethods}`;
    }

    if (receiptEmail) {
      query += `&receiptEmail=${receiptEmail}`;
    }

    if (isTest) {
      query += `&isTest=${isTest}`;
    }

    return post<any>(`${api}/checkout/${checkoutId}/payment${query}`, {}, getToken());
  },
  updatePickupTimes: async (checkoutInstanceId: string, pickup: IPickupTimesRequestBody) => {
    return post<IAvailableTimeDto[]>(`${api}/availableordertime/${checkoutInstanceId}`, pickup, getToken());
  },
  validateOrder: async (checkoutId: string, productionUnitId: number) => {
    return post<undefined>(`${api}/order/validate?checkoutId=${checkoutId}`, { productionUnitId }, getToken());
  },
  finalizeCheckout: async (checkoutId: string) => {
    return post<ICheckoutDto>(`${api}/checkout/${checkoutId}/finalize`, {}, getToken());
  },

  removeDiscount: async (checkoutId: string, discountId: string) => {
    return del<ICheckoutDto>(`${api}/checkout/${checkoutId}/discount/${discountId}`, getToken());
  },
  removeGiftcard: async (checkoutId: string, giftcardId: string) => {
    return del<ICheckoutDto>(`${api}/checkout/${checkoutId}/giftcard/${giftcardId}`, getToken());
  },
  removeDeliveryInfo: async (checkoutId: string) => {
    return del<undefined>(`${api}/checkout/${checkoutId}/delivery`, getToken());
  },
};

export default CheckoutService;
