import { useNavigate, useParams } from "react-router";

import Smiley from "components/ui/smiley/Smiley";
import useTranslationText from "hooks/useTranslationText";
import { useMenuSelector } from "store/menu";

import "./Sidebar.scss";

interface ISidebarProps {
  isClosing?: boolean;
  handleCategoryClick?: (categoryId: number) => void;
}

export default function Sidebar(props: ISidebarProps) {
  const { handleCategoryClick, isClosing } = props;

  const { categories } = useMenuSelector();
  const { categoryId } = useParams();
  const redirect = useNavigate();

  const { getTranslationText } = useTranslationText();

  function handleClick(chosenCategoryId: number) {
    handleCategoryClick ? handleCategoryClick(chosenCategoryId) : redirect(`/menu/${chosenCategoryId}`);
  }

  const activeCategoryId: number = categoryId ? parseInt(categoryId) : 0;

  return (
    <div className={`sidebar-container ${isClosing ? "closing" : ""}`}>
      <div className="sidebar">
        <Smiley />

        {categories.map(function (category) {
          return (
            <p key={category.id} onClick={() => handleClick(category.id)} className={`sidebar-item ${activeCategoryId === category.id ? "active" : ""}`}>
              <span className="dot"></span> {getTranslationText(category.name)}
            </p>
          );
        })}
      </div>
    </div>
  );
}
