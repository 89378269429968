import { ReactNode } from "react";
import PageTitle from "../PageTitle";

interface IPageProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

export default function Page(props: IPageProps) {
  const { title, subtitle, children } = props;

  return (
    <>
      {title && <PageTitle title={title} subtitle={subtitle} />}
      {children}
    </>
  );
}
