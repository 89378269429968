import { IProductDto } from "@crunchit/types";
import { isBlockedProduct } from "@crunchit/utilities";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useAppSelector } from "store/app";
import { useMenuSelector } from "store/menu";
import { useBasketSelector } from "store/basket";

import useProducts from "hooks/useProducts";

import Product from "components/menu/Product";
import SimpleTotal from "components/basket/totals/SimpleTotal";
import Page from "components/ui/page/Page";

import "./Precheckout.scss";

export default function Precheckout() {
  const { appIsInitialized, appSettings } = useAppSelector();
  const { context, precheckoutCategory, isLoading: menuDataIsLoading } = useMenuSelector();
  const { basketProductTotal } = useBasketSelector();
  const { t } = useTranslation();
  const redirect = useNavigate();

  let {
    blockedProducts,
    blockedChoices,

    loadProducts,
    loadWarehouse,

    products,

    getAvailableProductIds,
  } = useProducts();

  useEffect(() => {
    if (appIsInitialized) {
      if (appSettings.precheckoutCategory) {
        loadWarehouse();
      } else {
        redirect("/checkout");
      }
    }
  }, [appIsInitialized, appSettings.precheckoutCategory, loadWarehouse, redirect]);

  useEffect(() => {
    if (precheckoutCategory && precheckoutCategory.products.length > 0) {
      const precheckoutProductIds = precheckoutCategory.products.map((p) => p.productId);
      const availableProducts = getAvailableProductIds(precheckoutProductIds);

      if (availableProducts.length === 0) {
        redirect("/checkout");
      }
    } else {
      redirect("/checkout");
    }
  }, [precheckoutCategory, getAvailableProductIds, redirect]);

  useEffect(() => {
    if (precheckoutCategory) {
      loadProducts(precheckoutCategory);
    }
  }, [precheckoutCategory, loadProducts]);

  // Not allowing modifiers in precheckout
  let precheckoutProducts = useMemo(() => {
    return products.map((p: IProductDto) => {
      let updatedProduct = Object.assign({}, p);
      updatedProduct.modifiers = [];
      return updatedProduct;
    });
  }, [products]);

  async function handleContinueClick() {
    redirect("/checkout");
  }

  if (!precheckoutCategory) {
    return <></>;
  }

  return (
    <>
      <div className="content-body">
        <Page title={t("pagetitles:PrecheckoutPage.Title")}>
          <div className="precheckout-content">
            <div className="precheckout-section">
              <header>
                <h2 className="precheckout-subtitle">
                  {t("precheckout:ContinueText.FirstText")}
                  <span> {t("precheckout:ContinueText.SecondText")}</span>
                </h2>
                <button className="button button-colored red" onClick={handleContinueClick}>
                  {t("common:Navigation.Continue")}
                </button>
              </header>
            </div>

            <div className="precheckout-section">
              <header>
                <h2 className="precheckout-subtitle">
                  {t("precheckout:AddItemsText.FirstText")}
                  <span> {t("precheckout:AddItemsText.SecondText")}</span>
                </h2>
              </header>

              <div className="precheckout-products">
                <h1 className="precheckout-products-title">
                  <span className="count">01</span>
                  {t("precheckout:AddExtraItemsTitle")}
                </h1>

                <div className="product-list">
                  {!menuDataIsLoading &&
                    context &&
                    precheckoutProducts.map(function (product: IProductDto) {
                      return <Product key={product.id} product={product} context={context} isBlockedProduct={isBlockedProduct(product.id, blockedProducts)} blockedChoices={blockedChoices} />;
                    })}
                </div>

                <SimpleTotal total={basketProductTotal} />

                <div className="product-cta-buttons">
                  <button className="button button-colored red" onClick={handleContinueClick}>
                    {t("common:Navigation.Continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </>
  );
}
