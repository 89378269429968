import { IBasketItemDto, IProductDto } from "@crunchit/types";
import { useBasketSelector, basketThunks } from "store/basket";
import { useCustomDispatch } from "store/useStore";

export default function useBasketUpdates() {
  const { basket, basketProducts } = useBasketSelector();

  const dispatch = useCustomDispatch();

  const handleBasketChange = async (values: { newCount: number; productId: number; product?: IProductDto }) => {
    const { productId, product, newCount } = values;

    if (newCount < 1) {
      // Count is zero - removing all matches for this product id
      const basketProductList = basketProducts.filter((p: IBasketItemDto) => p.productId === productId);
      for (let i = 0; i < basketProductList.length; i++) {
        await dispatch(basketThunks.removeBasketItem({ basketId: basket.id, basketProductId: basketProductList[i].id }));
      }
    } else {
      // An orderlist product is not directly comparable with the basket product
      const basketProduct = basketProducts.find((p: IBasketItemDto) => p.productId === productId);
      if (basketProduct) {
        dispatch(basketThunks.updateBasketItem({ basketId: basket.id, basketProduct, updatedCount: newCount }));
      } else if (product) {
        dispatch(basketThunks.addBasketItem({ product, count: newCount }));
      }
    }
  };

  return { handleBasketChange };
}
