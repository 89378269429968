import { IBasketItemDto, ILabelDto, IProductDto } from "@crunchit/types";
import { getRegularPriceDayInterval } from "@crunchit/utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Labels from "../Labels";
import selectIcon from "assets/img/icons/select.svg";
import Counter from "components/ui/Counter";
import useTranslationText from "hooks/useTranslationText";
import { useBasketSelector } from "store/basket";

interface IProductDetailsProps {
  product: IProductDto;
  isExpanded: boolean;
  labels?: ILabelDto[];
  toggleModifiers: () => void;
  countChanged: (newCount: number) => void;
}

export default function ProductDetails(props: IProductDetailsProps) {
  const { product, isExpanded, labels } = props;
  const { countChanged, toggleModifiers } = props;

  const { t } = useTranslation();
  const { isLoading: basketIsLoading, basket } = useBasketSelector();

  const { getTranslationText } = useTranslationText();

  const showModifierToggleButton = product.modifiers.length > 0;
  const isOnSale = product.productPriceDaily && product.price !== product.originalPrice;

  let regularPriceDayInterval;

  if (isOnSale) {
    try {
      const interval = getRegularPriceDayInterval(product.productPriceDaily);

      if (interval.firstDay) {
        const firstDay = t("common:Days." + interval.firstDay);

        if (interval.lastDay) {
          regularPriceDayInterval = `${firstDay} - ${t("common:Days." + interval.lastDay).toLowerCase()}`;
        } else {
          regularPriceDayInterval = firstDay;
        }
      }
    } catch (error) {}
  }

  let productLabels: ILabelDto[] = [];

  if (product.labelIds && labels) {
    product.labelIds.forEach((labelId) => {
      const label = labels.find((l) => l.id === labelId);

      if (label) {
        productLabels.push(label);
      }
    });
  }

  const currentItemCount = useMemo(() => {
    let count = 0;

    if (product && basket) {
      const findInCart = basket.items.find((prod: IBasketItemDto) => prod.productId === product.id);

      if (findInCart) {
        count = findInCart.amount;
      }
    }

    return count;
  }, [product, basket]);

  const hasProducts = useMemo(() => {
    return currentItemCount > 0;
  }, [currentItemCount]);

  return (
    <div className={`product-details ${hasProducts ? "active" : ""}`}>
      <div className="product-name-container" onClick={toggleModifiers}>
        <h1 className="product-name">{getTranslationText(product.name)}</h1>

        <div className="product-subtitle-container">
          {product.subtitle && <h2 className="product-subtitle">{getTranslationText(product.subtitle)}</h2>}
          {productLabels.length > 0 && <Labels labels={productLabels} />}
        </div>

        <p className="product-description small-text">{getTranslationText(product.description)}</p>
      </div>

      <div className={`product-action-container collapsible-section ${isExpanded ? "expanded" : "collapsed"}`}>
        <div>
          <h3 className="product-price">
            {product.price}
            <span className="product-price-text">{t("common:Currency")}</span>
          </h3>

          {isOnSale && regularPriceDayInterval && (
            <p className="product-price-sale">
              {regularPriceDayInterval}
              <span>{product.originalPrice},-</span>
            </p>
          )}
        </div>

        {showModifierToggleButton && (
          <button className={`button button-colored red bold-text ${isExpanded ? "active" : ""}`} onClick={toggleModifiers} id={product.id.toString()}>
            {t("products:ModifierTitle")}
            <span style={{ backgroundImage: `url(${selectIcon})` }}></span>
          </button>
        )}

        {!showModifierToggleButton && (
          <div className="counter-container">
            <Counter count={currentItemCount} isDisabled={basketIsLoading} handleChange={countChanged} />
          </div>
        )}
      </div>
    </div>
  );
}
