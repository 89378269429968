import { ILabelDto } from "@crunchit/types";
import useTranslationText from "hooks/useTranslationText";

export default function LabelInfo(props: { labels: ILabelDto[] }) {
  const { labels } = props;

  const { getTranslationText } = useTranslationText();

  return (
    <div className="label info-label">
      <p className="label-icon">i</p>

      <div className="info-box">
        {labels.map(function (label) {
          return label ? (
            <p key={label.id} className="info-box-text">
              {getTranslationText(label.name) && (
                <span className="label-name">
                  {getTranslationText(label.name)}
                  <br />
                </span>
              )}
              {getTranslationText(label.description) && (
                <span className="label-description">
                  {getTranslationText(label.description)}
                  <br />
                </span>
              )}
            </p>
          ) : null;
        })}
      </div>
    </div>
  );
}
