import { get, post } from "@crunchit/http-client";
import { IPayment } from "models/payment";
import { getToken, getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_PAYMENT_URI || `payment.${getEnvironment()}crunchy.ninja/api`;

const PaymentService = {
  getPaymentByCheckoutId: async (checkoutId: string) => {
    return get<IPayment>(`${api}/payment/checkout/${checkoutId}`, getToken());
  },
  cancelPayment: async (paymentId?: string, isTest = false) => {
    return post<IPayment>(`${api}/payment/cancel`, { paymentId, isTest }, getToken());
  },
};

export default PaymentService;
