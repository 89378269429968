import useHeaderNavigation from "./useHeaderNavigation";
import { ILayoutOptions } from "models/layout";
import Topbar from "components/navigation/Topbar";
import Sidebar from "components/navigation/Sidebar";
import Basket from "components/basket/Basket";
import Logo from "components/ui/Logo";

import "./Header.scss";

// A wrapper including the topbar header and expandable cart and navigation
export default function Header(props: Partial<ILayoutOptions>) {
  const { hideBasket, hideSidebar } = props;

  const { hideNavigation, basketIsOpen, basketIsClosing, sidebarIsOpen, sidebarIsClosing, clickHandlers } = useHeaderNavigation();

  return (
    <>
      <Logo handleClick={clickHandlers.logo} />

      <header className={`header-container ${basketIsOpen || sidebarIsOpen ? "expanded-header-container" : ""}`}>
        <Topbar hideBasket={hideBasket || hideNavigation} hideSidebar={hideSidebar || hideNavigation} clickHandlers={clickHandlers} />

        {sidebarIsOpen && <Sidebar isClosing={sidebarIsClosing} handleCategoryClick={clickHandlers.sidebarCategory} />}

        {basketIsOpen && <Basket isClosing={basketIsClosing} handleContinue={clickHandlers.continue} handleBasketToggle={clickHandlers.basket} />}
      </header>
    </>
  );
}
