import { useTranslation } from "react-i18next";

import OrderList from "components/basket/OrderList";
import "./Empty.scss";

interface IEmptyProps {
  handleButtonClick: () => void;
}

export default function Empty(props: IEmptyProps) {
  const { handleButtonClick } = props;
  const { t } = useTranslation();

  return (
    <div className="empty-checkout-basket-section">
      <OrderList products={[]} />
      <button className="button button-colored red" onClick={handleButtonClick}>
        {t("common:Navigation.ContinueShopping")}
      </button>
    </div>
  );
}
