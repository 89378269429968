import { useAppSelector } from "store/app";

import "./TermsIframe.scss";

interface ITermsIframeProps {
  handleDismiss: () => void;
}

export default function TermsIframe(props: ITermsIframeProps) {
  const { handleDismiss } = props;
  const { appSettings } = useAppSelector();

  return (
    <div className="terms-iframe">
      <div className="terms-iframe-wrapper">
        <button className="exit-btn" onClick={handleDismiss}>
          <i className="icon exit-white-icon"></i>
        </button>

        <iframe src={appSettings.terms} title="Handelsbetingelser ved online køb hos Bone's Restauranter" referrerPolicy="no-referrer"></iframe>
      </div>
    </div>
  );
}
